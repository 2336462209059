<template>
    <el-dialog
        append-to-body
        center
        :title="title"
        :visible.sync="visible"
        width="22%"
        @close="closeDialog"
    >
        <!-- <el-select v-model="value" placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
        </el-select>-->
        <div class="flex-sb">
            <el-cascader
                :key="isResouceShow"
                ref="cascaderHandle"
                v-model="departVal"
                style="margin-bottom: 10px;width:100%"
                :options="departmentsArr"
                :props="{
          expandTrigger: 'hover',
          checkStrictly: true,
          label: 'name',
          value: 'id'
        }"
                placeholder="选择部门"
                clearable
                @change="selDepartVal"
            />
            <el-select v-model="staffId" placeholder="请选择员工" clearable style="width:100%">
                <el-option
                    v-for="item in staffOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
            </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit">保存</el-button>
            <el-button @click="closeDialog">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { departments, staff } from '@/api/public_content.js';
export default {
    name: 'ShowDialog',
    components: {},
    props: {
        showDialog: {
            default: false,
        },
        title: {
            type: String,
        },
    },
    data() {
        return {
            visible: false,
            departmentsArr: [],
            departVal: '',
            staffOption: [],
            staffId: '',
            isResouceShow: 0,
        };
    },
    created() {
        this.getDepartment();
    },
    mounted() {},
    methods: {
        getDepartment() {
            departments().then((res) => {
                if (res.code == 0) {
                    this.departmentsArr = this.common.getTreeData(
                        res.data.list
                    );
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        closeDialog() {
            ++this.isResouceShow;
            this.departVal = [];
            this.staffId = '';
            this.$emit('update:showDialog', false);
            this.departmentsArr = [];
            this.staffOption = [];
        },
        submit() {
            this.$emit('submitPartment', this.staffId);
            this.closeDialog();
        },
        selDepartVal() {
            this.staffOption = [];
            this.staffId = '';
            if (this.departVal[this.departVal.length - 1] === undefined) return;
            this.$refs.cascaderHandle.dropDownVisible = false;
            staff({
                departmentId: this.departVal[this.departVal.length - 1],
            }).then((res) => {
                if (res.code == 0) {
                    this.staffOption = res.data.list;
                    console.log(this.staffOption, 'this.staffOption');
                    this.staffId = '';
                } else {
                    this.$message.error(res.error);
                }
            });
        },
    },
    watch: {
        showDialog() {
            this.visible = this.showDialog;
            this.getDepartment();
        },
    },
};
</script>
